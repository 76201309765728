import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import AssetCard from "../../../../components/asset-card";
import TableContent from "../../../../components/table-content";
import { ReactComponent as BitcoinRoundedIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg';
import './transactions-list-page.scss';
import { apiService } from "../../../../services/api.service";
import LoadingSpinner from "../../../../components/loading-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { loadingSliceActions, LoadingState } from '../../../../store/loading.slice';
import { UserState } from '../../../../store/user.slice';
import { dateFormatter } from '../../../../utils/date-utils';
import CopyText from '../../../../components/copy-text';
import TableExport from '../../../../components/table-export';
import { TransactionsFilters } from '../../../../models/transaction.model';
import TableFilters from '../../../../components/table-filters';
import TablePagination from '../../../../components/table-pagination';
import { formatNumber } from '../../../../utils/number-utils';

enum AssetType {
  'BTC' = 'Bitcoin',
  'ETH' = 'Ethereum',
  'USDT' = 'USDT',
}

const TransactionsListPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading);
  const { walletAlias, walletAddress, balance, asset, status } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      if (!walletAddress) return;
      dispatch(loadingSliceActions.startLoading())
      try {
        const pageInfo = {
          page: currentPage - 1,
          size: 10,
        };
        const balanceData = await apiService.getWalletBalance(walletAddress);
        setWalletBalance(balanceData.data);
        const transactionsData = await apiService.getTransactionsByWallet(walletAddress, pageInfo);
        setTotalPages(transactionsData.data.totalPages);
        setTransactions(transactionsData.data.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        dispatch(loadingSliceActions.stopLoading())
      }
    };

    fetchData();
  }, [walletAddress, currentPage]);

  const columns = [
    {
      Header: 'Transaction Hash',
      accessor: 'txHash',
      Cell: ({ value }: { value: string }) => (
        <div className="transaction-hash-cell">
          <div className="icon-wrapper" onClick={() => handleIconClick(value)} role="button">
            <EyeIcon />
          </div>
          <span className="truncated-text">{value}</span>
          <CopyText value={value} />
        </div>
      ),
    },
    {
      Header: 'Method',
      accessor: 'walletReferenceStatus',
      Cell: ({ value }: { value: string }) => (
        <span className={`${value.toLocaleLowerCase()}`}>{value}</span>
      ),
    },
    {
      Header: 'Status',
      accessor: 'blockNumber',
      Cell: ({ value }: { value: string }) => (
        <span className='status-chip-account-details active'>Approved</span>
      ),
    },
    {
      Header: 'Timestamp',
      accessor: 'date',
      Cell: ({ value }: { value: string }) => {
        return (
          <span>{dateFormatter(value)}</span>
        )
      }
    },
    {
      Header: 'From',
      accessor: 'from',
      Cell: ({ value, row }: { value: string, row: any }) => {
        return (
          <>
            {row.original.fromAlias ? (<div className="from-to flex-column">
              <span>{row.original.fromAlias}</span>
              <span className='truncated-text secondary-text'>{value}</span>
            </div>) :
              (<div className="from-to flex-column">
                <span className='truncated-text'>{value}</span>
              </div>)}
          </>
        )
      }
    },
    {
      Header: 'To',
      accessor: 'to',
      Cell: ({ value, row }: { value: string, row: any }) => {
        return <>{
          row.original.fromAlias ? (<div className="from-to flex-column">
            <span>{row.original.toAlias}</span>
            <span className='truncated-text secondary-text'>{value}</span>
          </div>) :
            (<div className="from-to flex-column">
              <span className='truncated-text'>{value}</span>
            </div>)
        }</>
      }
    },
    {
      Header: 'Amount',
      accessor: 'value',
      Cell: ({value} : {value:number}) => {
        return <div>{formatNumber(value)}</div>
      }
    },
  ];

  const renderAssetIcon = () => {
    switch (asset) {
      case 'BTC':
        return <BitcoinRoundedIcon />;
      case 'ETH':
        return <EthereumRoundedIcon />;
      case 'USDT':
        return <USDTRoundedIcon />;
      default:
        return null;
    }
  };

  const handleIconClick = (transactionHash: string) => {
    navigate('/app/wallet-management/transactions-details', { state: { transactionHash } });
  };



  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const assetFullName = AssetType[asset as keyof typeof AssetType];


  return (
    <div className="transactions-list-page page-inner">
      <div className="page-header flex-row flex-align-space-between-center">
        <span className="page-title">Transactions List</span>
        <div className="wallet-info flex-row flex-align-center-center flex-gap-5 hide-sm">
          {renderAssetIcon()}
          <div className="asset-info flex-row flex-align-center-center flex-gap-20">
            <span className="token">Token</span>
            <span className="asset">{assetFullName}</span>
            <span className="asset">{`(${asset})`}</span>
          </div>
        </div>
      </div>
      <div className="flex-row flex-align-space-evenly-center flex-gap-30">
        <AssetCard walletAlias={walletAlias} walletAddress={walletAddress} balance={walletBalance?.toFixed(2).toString()} status={status} asset={asset} />
      </div>
      <div className="page-content flex-column flex-gap-20">
        <div className="filters-section table-pagination flex-row flex-gap-10 flex-align-end-center">
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        {isLoading && !transactions.length ? <LoadingSpinner /> : <TableContent columns={columns} data={transactions || []} />}
      </div>
    </div>
  );
}

export default TransactionsListPage;
