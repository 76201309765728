import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './analytics-chart.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export interface Dataset {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth?: number;
    backgroundColor: string;
    tension?: number;
    pointBorderColor?: string;
    pointBorderWidth?: number;
    pointRadius?: number;
    pointHoverRadius?: number;
    fill: boolean;
}

interface AnalyticsChartProps {
    data: {
        labels: string[];
        datasets: Dataset[];
    };
}

const roundUp = (value: number) => {
    const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
    const roundedValue = Math.ceil(value / magnitude) * magnitude;
    return roundedValue;
};

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({ data }) => {
    const allValues = data.datasets.flatMap(dataset => dataset.data);
    const maxValue = Math.max(...allValues);

    const maxYValue = roundUp(maxValue);

    const stepSize = maxYValue / 5;
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: any) {
                        const datasetLabel = tooltipItem.dataset.label || '';
                        const transactionCount = tooltipItem.raw;
                        return `${datasetLabel}: ${transactionCount} transactions`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#FFF',
                    padding: 30,
                },
            },
            y: {
                min: 0,
                max: maxYValue,
                ticks: {
                    stepSize: stepSize,
                    color: '#fff',
                },
                beginAtZero: true,
                grid: {
                    drawBorder: false,
                    color: '#4f4f4f',
                },
            },
        },
    };

    const transformedData = {
        ...data,
        datasets: data.datasets.map((dataset) => ({
            ...dataset,
            data: dataset.data.map((item) => item),
        })),
    };

    return (
        <div className='analytics-chart-container'>
            <div className='analytics-chart'>
                <Line data={transformedData} options={options} />
            </div>
        </div>
    );
};

export default AnalyticsChart;
