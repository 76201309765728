import { useState } from 'react';
import './table-pagination.scss';
import Button from './button';

interface TablePaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const goToFirstPage = () => onPageChange(1);
    const goToLastPage = () => onPageChange(totalPages);
    const goToNextPage = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };
    const goToPreviousPage = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    return (
        <div className="table-pagination-container flex-row flex-gap-10 flex-align-end-center">
            <Button 
                theme='filters-button'
                onClick={goToFirstPage}
                disabled={currentPage === 1}
                label='First'
            />
            <Button 
                theme='filters-button'
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                label='&lt;'
            />
            <span className="pagination-info">
                Page {currentPage} of {totalPages}
            </span>
            <Button 
                theme='filters-button'
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                label='&gt;'
            />
            <Button 
                theme='filters-button'
                onClick={goToLastPage}
                disabled={currentPage === totalPages}
                label='Last'
            />
        </div>
    );
};

export default TablePagination;
