
export const formatNumber = (value?: number) => {
    if(!value) 
        return 0;
    if (isNaN(value)) {
        throw new Error('Invalid number');
    }

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
}