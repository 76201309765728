import TableContent from "../../../../components/table-content";
import { ReactComponent as BtcIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../../assets/icons/usdt-rounded.svg';
import CustomChart from "../../../../components/custom-chart";
import TransactionsDetailsChart from "../../../../components/transactions-details-chart";

const data = [
    {
        clientName: 'First Name, Last Name',
        status: 'Blocked',
        email: 'example@email.com',
        telephone: '+50 678 123 456',
        accounts: {
            eth: '413.2 K',
            btc: '82.4 K',
            usdt: '145.4 K',
        },
    },
    {
        clientName: 'First Name, Last Name',
        status: 'Suspended',
        email: 'example@email.com',
        telephone: '+50 678 123 456',
        accounts: {
            eth: '413.2 K',
            btc: null,
            usdt: '145.4 K',
        },
    },
    {
        clientName: 'First Name, Last Name',
        status: 'Blocked',
        email: 'example@email.com',
        telephone: '+50 678 123 456',
        accounts: {
            eth: null,
            btc: '82.4 K',
            usdt: '145.4 K',
        },
    },
];

const columns = [
    {
        Header: 'Client Name',
        accessor: 'clientName',
        Cell: ({ cell: { value } }: { cell: any }) => (
            <span style={{whiteSpace: 'nowrap'}}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }: { cell: any }) => (
            <span className={`status-chip-account-list ${value.toLowerCase()}`}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Telephone',
        accessor: 'telephone',
    },
    {
        Header: 'Accounts',
        accessor: 'accounts',
        Cell: ({ cell: { value } }: { cell: any }) => (
            <div className="flex-row flex-gap-20 flex-align-start-center">
                {
                    value.btc && (
                        <div className="flex-row flex-gap-10 flex-align-start-center no-wrap
                        ">
                            <BtcIcon width={24}/>
                            <span style={{whiteSpace: 'nowrap'}}>
                                {value.btc}
                            </span>
                        </div>
                    )}
                {value.eth && (
                    <div className="flex-row flex-gap-10 flex-align-start-center">
                        <EthIcon width={24}/>
                        <span style={{whiteSpace: 'nowrap'}}>
                            {value.eth}
                        </span>
                    </div>
                )}
                {value.usdt && (
                    <div className="flex-row flex-gap-10 flex-align-start-center">
                        <UsdtIcon width={24}/>
                        <span style={{whiteSpace: 'nowrap'}}>
                            {value.usdt}
                        </span>
                    </div>
                )}
            </div>
        ), 
    },
];

const RiskManagementPage = () => {
    return (
        <div className="risk-management-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Risk Management</span>
            </div>
            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                    <span className="table-title">Price Sensivity</span>
                </div>
                <TransactionsDetailsChart/>
            </div>
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Current liquidity status</span>
                </div>
                <TableContent columns={columns} data={data || []} />
            </div>
        </div>
    )
}

export default RiskManagementPage;