import { useState, useEffect } from "react";
import AnalyticsChart, { Dataset } from "./analytics-chart";
import './asset-analytics.scss';
import AppSelect from "./form/base/app-select";
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { apiService } from "../services/api.service";

const quarterOptions = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' }
];

const yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
];

interface ChartData {
    labels: string[];
    datasets: Dataset[];
}

interface TransactionData {
    quarterName: string;
    firstMonth: MonthData;
    secondMonth: MonthData;
    thirdMonth: MonthData;
}

interface MonthData {
    monthName: string;
    btcTransactions: number;
    usdtTransactions: number;
    ethTransactions: number;
}

const getLabelsForQuarter = (quarter: string) => {
    const monthsMap: { [key: string]: string[] } = {
        Q1: ["January", "February", "March"],
        Q2: ["April", "May", "June"],
        Q3: ["July", "August", "September"],
        Q4: ["October", "November", "December"],
    };
    return monthsMap[quarter] || [];
};

const extractDataForQuarter = (data: TransactionData[], quarter: string) => {
    const quarterData = data.find(q => q.quarterName === quarter);
    if (!quarterData) return { labels: [], btc: [], usdt: [], eth: [] };

    return {
        labels: [
            quarterData.firstMonth.monthName,
            quarterData.secondMonth.monthName,
            quarterData.thirdMonth.monthName,
        ],
        btc: [
            quarterData.firstMonth.btcTransactions,
            quarterData.secondMonth.btcTransactions,
            quarterData.thirdMonth.btcTransactions,
        ],
        usdt: [
            quarterData.firstMonth.usdtTransactions,
            quarterData.secondMonth.usdtTransactions,
            quarterData.thirdMonth.usdtTransactions,
        ],
        eth: [
            quarterData.firstMonth.ethTransactions,
            quarterData.secondMonth.ethTransactions,
            quarterData.thirdMonth.ethTransactions,
        ],
    };
};

const AssetAnalytics = () => {
    const [selectedQuarter, setSelectedQuarter] = useState<'Q1' | 'Q2' | 'Q3' | 'Q4'>('Q1');
    const [selectedYear, setSelectedYear] = useState('2021');
    const [filteredData, setFilteredData] = useState<ChartData>({ labels: [], datasets: [] });
    const [transactions, setTransactions] = useState<TransactionData[]>([]);

    const changeQuarter = (value: string) => {
        setSelectedQuarter(value as 'Q1' | 'Q2' | 'Q3' | 'Q4');
    };

    const changeYear = (value: string) => {
        setSelectedYear(value);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const result = await apiService.getQuarterlyTransactions(selectedYear);
                setTransactions(result.data);
            } catch (e) {
                console.error('Error fetching transactions:', e);
            }
        };

        fetchTransactions();
    }, [selectedYear]);

    useEffect(() => {
        const data = extractDataForQuarter(transactions, selectedQuarter);
    
        const chartData: ChartData = {
            labels: data.labels,
            datasets: [
                {
                    label: 'BTC',
                    data: data.btc,
                    borderColor: '#FCBE2D',
                    borderWidth: 2,
                    backgroundColor: '#FCBE2D',
                    tension: 0.2,
                    pointBorderColor: '#FCBE2D',
                    pointRadius: 5,
                    fill: false,
                },
                {
                    label: 'ETH',
                    data: data.eth,
                    borderColor: '#7F7F7F',
                    backgroundColor: '#7F7F7F',
                    tension: 0.2,
                    pointBorderColor: '#7F7F7F',
                    pointRadius: 5,
                    fill: false,
                },
                {
                    label: 'USDT',
                    data: data.usdt,
                    borderColor: '#00B69B',
                    backgroundColor: '#00B69B',
                    tension: 0.2,
                    pointBorderColor: '#00B69B',
                    pointRadius: 5,
                    fill: false,
                },
            ],
        };
    
        setFilteredData(chartData);
    }, [selectedQuarter, transactions]);
    

    return (
        <div className='asset-analytics statistic-card flex-column flex-gap-20'>
            <div className='card-header flex-row'>
                <div className='card-title'>
                    <span>Asset Analytics</span>
                </div>
                <div className="filters-container flex-gap-20">
                    <AppSelect
                        options={yearOptions}
                        onChange={changeYear}
                        value={selectedYear}
                        icon={<CalendarIcon />}
                    />
                    <AppSelect
                        options={quarterOptions}
                        onChange={changeQuarter}
                        value={selectedQuarter}
                        icon={<CalendarIcon />}
                    />
                </div>
            </div>
            <div className='chart-container'>
                <AnalyticsChart data={filteredData} />
            </div>
        </div>
    );
};

export default AssetAnalytics;
