import { Controller, useForm, useWatch } from "react-hook-form";
import AppFormSelect from "../../../../components/form/app-form-select";
import { Asset, Network, WalletCrypto, WalletInternalType } from "../../../../models";
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg'
import AppFormInput from "../../../../components/form/app-form-input";
import './add-crypto-wallet-page.scss';
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/modal";
import { useEffect, useState } from "react";
import { apiService } from "../../../../services/api.service";



const initialNetworks: Network[] = [
    {
        title: 'BTC',
        value: 'BTC20',
        min: '1.00 USDT',
        depositArrival: 5,
        acceptedAssets: [Asset.BTC]
    },
    {
        title: 'Ethereum',
        value: 'ERC20',
        min: '1.00 USDT',
        depositArrival: 64,
        acceptedAssets: [Asset.ETH, Asset.USDT]
    },
]

const assets = [
    { label: 'BTC', value: 'BTC' },
    { label: 'ETH', value: 'ETH' },
    { label: 'USDT', value: 'USDT' },
]

const walletTypesArray = Object.keys(WalletInternalType).map(key => ({
    label: key.replace(/_/g, ' '),
    value: WalletInternalType[key as keyof typeof WalletInternalType]
}));



const AddCryptoWalletPage = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); 
    const [networks, setNetworks] = useState<Network[] | []>([]);

    const form = useForm<WalletCrypto>({
        mode: 'onTouched',
        defaultValues: {
            asset: '',
            network: {},
            walletAddress: '',
            walletAlias: '',
            internalType: WalletInternalType.MPC,
            privateKey: '',
        },
    });

    const asset  = useWatch({
        control: form.control,
        name: 'asset',
    });

       
    useEffect(() => {
        if (asset) {
            const filteredNetworks = initialNetworks.filter((network) =>
                network.acceptedAssets.includes(asset as Asset)
            );

            if (filteredNetworks.length > 0) {
                form.setValue('network', filteredNetworks[0]);
                setNetworks(filteredNetworks);
            } else {
                form.setValue('network', null);
                setNetworks([]);
            }
        } else {
            form.setValue('network', null);
            setNetworks([]);
        }
        
    }, [asset, form.reset]);



    const handleCancel = () => {
        navigate(-1)
    }
    
    const handleSave = async (data: WalletCrypto) => {
        if (form.formState.isValid) {
            setShowModal(true);
            try {
                await apiService.createInternalWallet(data);
            } catch (e) {
                console.log(e);
                
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        navigate('/'); 
    };
    return (
        <div className="add-crypto-wallet-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Add Crypto Wallet</span>
            </div>
            <div className="page-content flex-row  flex-gap-100">
                <div className="flex-column flex-50 flex-gap-20 flex-align-start-start" >
                    <AppFormSelect
                        label="Select Asset"
                        name="asset"
                        options={assets}
                        control={form.control}
                        placeholder="Asset"
                        validations={{ required: true }}
                    />
                    <div className="networks-container flex-column flex-align-start-start flex-gap-10">
                        <span className="networks-label">Select Network</span>
                        <Controller
                            name="network"
                            control={form.control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <>
                                    {networks.map((network) => (
                                        <div
                                            key={network.value}
                                            onClick={() => field.onChange(network)}
                                            className={`network-container flex-column flex-gap-10 ${field.value?.value === network.value && 'selected'}`}
                                        >
                                            <div className="flex-row flex-align-space-between-center">
                                                <span className="title">{network.title}{` (${network.value})`}</span>
                                                <CheckIcon className={`check-icon ${field.value?.value === network.value && 'selected'}`} />
                                            </div>
                                            <div className="flex-column flex-align-start-start flex-gap-5">
                                                <p className="subtitle">Min. deposit amount: <span>{network.min}</span></p>
                                                <p className="subtitle">Deposit arrival: <span>{network.depositArrival}</span></p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="flex-column flex-50 flex-gap-20 flex-align-start-center">
                    <AppFormInput
                        name="walletAddress"
                        control={form.control}
                        label="Add Wallet Address"
                        validations={{ required: true }}
                        placeholder="Wallet address"
                    />
                    <AppFormInput
                        name="walletAlias"
                        control={form.control}
                        label="Add Alias (Wallet Name)"
                        placeholder="Wallet Name"
                        validations={{ required: true }}
                    />
                    <AppFormSelect
                        label="Select Wallet Type"
                        name="internalType"
                        options={walletTypesArray}
                        control={form.control}
                        placeholder="Wallet Type"
                        validations={{ required: true }}
                    />
                    <AppFormInput
                        name="privateKey"
                        control={form.control}
                        label="Private Key"
                        placeholder="Private Key"
                        validations={{ required: true }}
                    />
                    <div className="buttons-container flex-row flex-align-center-end flex-gap-30">
                        <Button 
                        theme="form-outline"
                        label="Cancel"
                        onClick={() => handleCancel()}
                         ></Button>
                        <Button
                        theme="form" 
                        label="Save" 
                        disabled={!form.formState.isValid}
                        onClick={form.handleSubmit(handleSave)}
                        ></Button>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal
                    message="Your Crypto Wallet has been successfully added"
                    buttonText="Go to Dashboard"
                    onClose={() => setShowModal(false)}
                    onButtonClick={form.handleSubmit(handleModalClose)}
                />
            )}
        </div>
    );
}

export default AddCryptoWalletPage;