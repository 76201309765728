import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ReactComponent as BtcIcon } from '../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthIcon } from '../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../assets/icons/usdt-rounded.svg';
import TableContent from '../../../components/table-content';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../services/api.service';
import { AccountModel } from '../account-management/provider-list/provider-list-page';
import { useSelector } from 'react-redux';
import { loadingSliceActions, LoadingState } from '../../../store/loading.slice';
import { AppStore } from '../../../store/store';
import { UserState } from '../../../store/user.slice';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../components/loading-spinner';
import { AccountTypeEnum, WalletInfoDetails } from '../../../models';
import CustomChart from '../../../components/custom-chart';
import DoughnutChart from '../../../components/doughnut-chart';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-icon.svg';
import './dashboard-page.scss';
import AnalyticsChart from '../../../components/analytics-chart';
import WalletCard from '../../../components/wallet-card';
import { ReactComponent as BitcoinIcon } from '../../../assets/icons/bitcoin-wallet.svg';
import { ReactComponent as USDTIcon } from '../../../assets/icons/usdt-wallet.svg';
import { ReactComponent as EthereumIcon } from '../../../assets/icons/ethereum-wallet.svg';
import AssetAnalytics from '../../../components/asset-analytics';
import TransactionsDetailsChart from '../../../components/transactions-details-chart';
import { ReactComponent as BitcoinRoundedIcon } from '../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../assets/icons/usdt-rounded.svg';
import StatisticalInfoCard from '../../../components/statistical-info-card';
import { ReactComponent as PositiveIcon } from '../../../assets/icons/positive.svg';
import TotalAssetsChart from '../../../components/total-assets-chart';
import { TopProvider, TotalAssets } from '../../../models/statistics.model';
import { formatNumber } from '../../../utils/number-utils';





const columnsRecent = [
    {
        Header: 'Method',
        accessor: 'walletReferenceStatus',
        Cell: ({ value }: { value: string }) => (
            <span className={`${value.toLocaleLowerCase()}`}>{value}</span>
        ),
    },
    {
        Header: 'Block',
        accessor: 'blockNumber',
        Cell: ({ value, row }: { value: string, row: any }) => (
            <>
                <div>{value}</div>
                {/* <div className="secondary-text">{row.original.confirmations}</div> */}
            </>
        ),
    },
    {
        Header: 'Timestamp',
        accessor: 'date',
        Cell: ({ value }: { value: string }) => {
            const formatDate = (isoDate: string) => {
                const date = new Date(isoDate);
                const formattedDate = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                const formattedTime = date.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                });
                return `${formattedDate} at ${formattedTime}`;
            };

            return (
                <>
                    <div>{formatDate(value)}</div>
                </>
            );
        },
    },
    {
        Header: 'From',
        accessor: 'from',
        Cell: ({ value, row }: { value: string, row: any }) => {
            return <>{
                row.original.fromAlias ? (<div className="from-to flex-column">
                    <span>{row.original.fromAlias}</span>
                    <span className='truncated-text secondary-text'>{value}</span>
                </div>) :
                    (<div className="from-to flex-column">
                        <span>{value}</span>
                    </div>)
            }</>
        }
        ,
    },
];

export const mockRecentData = [
    {
        method: 'SENT',
        block: '123456',
        timestamp: '2024-11-05 10:00:00',
        from: '0x123456789abcdef',
        fromAlias: 'Test RGS',
        confirmations: '12 confirmations',
        relativeTime: '5 minutes ago',
    },
    {
        method: 'RECEIVED',
        block: '123457',
        timestamp: '2024-11-05 09:45:00',
        from: '0xabcdef123456789',
        fromAlias: 'ProvAcc',
        confirmations: '20 confirmations',
        relativeTime: '20 minutes ago',
    },
    {
        method: 'RECEIVED',
        block: '123458',
        timestamp: '2024-11-05 09:30:00',
        from: '0xa1b2c3d4e5f6789',
        fromAlias: 'ProvEthWall',
        confirmations: '8 confirmations',
        relativeTime: '35 minutes ago',
    },
    {
        method: 'SENT',
        block: '123459',
        timestamp: '2024-11-05 09:15:00',
        from: '0x123abcdef456789',
        fromAlias: 'ProvEthWall',
        confirmations: '15 confirmations',
        relativeTime: '50 minutes ago',
    },
];


export interface WalletCard {
    walletType: string;
    total: number;
    percentageChange: number;
    description: string;
    icon: ReactNode;
}



const walletCardsInitial: WalletCard[] = [
    {
        walletType: 'BTC',
        total: 0,
        percentageChange: 8.5,
        description: 'Up from yesterday',
        icon: <BitcoinIcon />
    },
    {
        walletType: 'ETH',
        total: 0,
        percentageChange: 2.1,
        description: 'Up from yesterday',
        icon: <EthereumIcon />
    },
    {
        walletType: 'USDT',
        total: 0,
        percentageChange: -4.3,
        description: 'Down from yesterday',
        icon: <USDTIcon />
    },
];







const DashboardPage = () => {
    const columnsAccountList = useMemo(
        () => [
            {
                Header: 'Provider Name',
                accessor: 'accountNo',
                Cell: ({ row }: any) => (
                    <div
                        className='truncated-text'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`provider-management/provider-details/${row.original.id}`)}
                    >
                        {row.original.accountNo}
                    </div>
                ),
            },
            {
                Header: ({ column }: any) => (
                    <div style={{ textAlign: 'center' }}>Status</div>
                ),
                accessor: 'status',
                Cell: ({ value }: { value: string }) => (
                    <div style={{ textAlign: 'center' }}>
                        <span className={`status-chip-account-list ${value.toLowerCase()}`}>
                            {value}
                        </span>
                    </div>
                ),
            },
            {
                Header: 'Wallets',
                accessor: 'wallets',
                Cell: ({ value }: { value: WalletInfoDetails[] }) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {value.map((wallet, index) => {
                            let icon = null;
                            switch (wallet.asset) {
                                case 'ETH':
                                    icon = <EthIcon key={index} />;
                                    break;
                                case 'BTC':
                                    icon = <BtcIcon key={index} />;
                                    break;
                                case 'USDT':
                                    icon = <UsdtIcon key={index} />;
                                    break;
                                default:
                                    icon = null;
                                    break;
                            }
                            return icon;
                        })}
                    </div>
                ),
            },
        ],
        []
    );

    const columnsSupplierList = useMemo(
        () => [
            {
                Header: 'Supplier Name',
                accessor: 'accountNo',
                Cell: ({ row }: any) => (
                    <div
                        className='truncated-text'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`supplier-management/supplier-details/${row.original.id}`)}
                    >
                        {row.original.accountNo}
                    </div>
                ),
            },
            {
                Header: ({ column }: any) => (
                    <div style={{ textAlign: 'center' }}>Status</div>
                ),
                accessor: 'status',
                Cell: ({ value }: { value: string }) => (
                    <div style={{ textAlign: 'center' }}>
                        <span className={`status-chip-account-list ${value.toLowerCase()}`}>
                            {value}
                        </span>
                    </div>
                ),
            },
            {
                Header: 'Wallets',
                accessor: 'wallets',
                Cell: ({ value }: { value: WalletInfoDetails[] }) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {value.map((wallet, index) => {
                            let icon = null;
                            switch (wallet.asset) {
                                case 'ETH':
                                    icon = <EthIcon key={index} />;
                                    break;
                                case 'BTC':
                                    icon = <BtcIcon key={index} />;
                                    break;
                                case 'USDT':
                                    icon = <UsdtIcon key={index} />;
                                    break;
                                default:
                                    icon = null;
                                    break;
                            }
                            return icon;
                        })}
                    </div>
                ),
            },
        ],
        []
    );

    const dispatch = useDispatch();
    const [walletCards, setWalletCards] = useState<WalletCard[]>(walletCardsInitial);
    const [accounts, setAccounts] = useState<AccountModel[]>([]);
    const [suppliers, setSuppliers] = useState<AccountModel[]>([]);
    const [recentTransactions, setRecentTransactions] = useState();
    const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading);
    const { user } = useSelector<AppStore, UserState>(state => state.user)
    const [topProviders, setTopProviders] = useState<TopProvider[]>([]);
    const sortedProviders = [...topProviders].sort((a, b) => b.totalSpentInUsd - a.totalSpentInUsd);
    const navigate = useNavigate();
    const [totalAssets, setTotalAssets] = useState<TotalAssets>();

    useEffect(() => {
        const getWalletsBalance = async (walletType: string) => {
            try {
                const response = await apiService.getBalanceByInternalCurrency(walletType);
                return response.data;
            } catch (e) {
                console.error(`Error fetching balance for ${walletType}:`, e);
                return 0;
            }
        };

        const updateWalletBalances = async (wallets: WalletCard[]) => {
            const updatedWallets = await Promise.all(
                wallets.map(async (wallet) => {
                    const balance = await getWalletsBalance(wallet.walletType);

                    return { ...wallet, total: +balance ? +balance : 0 };
                })
            );
            setWalletCards(updatedWallets);
        };

        updateWalletBalances(walletCards);
    }, [])

    useEffect(() => {
        // const getAccounts = async () => {
        //     dispatch(loadingSliceActions.startLoading())

        //     try {
        //         const response = await apiService.getAccountsByType(AccountTypeEnum.PROVIDER);
        //         return response.data;
        //     } catch (e) {
        //         return [];
        //     } finally {
        //         dispatch(loadingSliceActions.stopLoading())
        //     }
        // }
        // const getSuppliers = async () => {
        //     dispatch(loadingSliceActions.startLoading())

        //     try {
        //         const response = await apiService.getAccountsByType(AccountTypeEnum.SUPPLIER);
        //         return response.data;
        //     } catch (e) {
        //         return [];
        //     } finally {
        //         dispatch(loadingSliceActions.stopLoading())
        //     }
        // }
        const getRecentTransactions = async () => {
            dispatch(loadingSliceActions.startLoading())
            try {
                const response = await apiService.getRecentTransactions();
                return response.data;
            } catch (e) {
                return [];
            } finally {
                dispatch(loadingSliceActions.stopLoading())
            }
        }

        // getAccounts().then((accounts) => {
        //     setAccounts(accounts);
        // })
        // getSuppliers().then((suppliers) => {
        //     setSuppliers(suppliers)
        // })
        getRecentTransactions().then((transactions) => {
            setRecentTransactions(transactions)
        })
    }, [user?.id])

    useEffect(() => {

    }, [])
    useEffect(() => {
        const getTopProviders = async () => {
            try {
                const result = await apiService.getTopProviders();
                return result.data;
            } catch (e) {

            }
        }
        getTopProviders().then((data) => {
            if (data)
                setTopProviders(data)
        })
    }, [])

    useEffect(() => {
        const totalAssets = async () => {
            try {
                const result = await apiService.getTotalAssets();
                return result.data;
            } catch (e) {

            }

        }

        totalAssets().then((data) => {
            if (data)
                setTotalAssets(data)
        })
    }, [])
    useEffect(() => {
        if (totalAssets) {
            const updatedWalletCards: WalletCard[] = [
                {
                    walletType: 'BTC',
                    total: totalAssets.btcValueInUSD,
                    percentageChange: totalAssets.btcValueEvolutionPercent,
                    description: totalAssets.btcValueEvolutionPercent >= 0 ? 'Up from yesterday' : 'Down from yesterday',
                    icon: <BitcoinIcon />,
                },
                {
                    walletType: 'ETH',
                    total: totalAssets.ethValueInUSD,
                    percentageChange: totalAssets.ethValueEvolutionPercent,
                    description: totalAssets.ethValueEvolutionPercent >= 0 ? 'Up from yesterday' : 'Down from yesterday',
                    icon: <EthereumIcon />,
                },
                {
                    walletType: 'USDT',
                    total: totalAssets.usdtValueInUSD,
                    percentageChange: totalAssets.usdtValueEvolutionPercent,
                    description: totalAssets.usdtValueEvolutionPercent >= 0 ? 'Up from yesterday' : 'Down from yesterday',
                    icon: <USDTIcon />,
                },
            ];
            setWalletCards(updatedWalletCards);
        }
    }, [totalAssets]);




    return (
        <div className="dashboard-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Dashboard</span>
            </div>
            <div className="flex-row flex-align-space-evenly-center flex-gap-30 md-flex-wrap">
                {walletCards.map((card, index) => (
                    <WalletCard
                        key={index}
                        walletType={card.walletType}
                        total={card.total}
                        percentageChange={card.percentageChange}
                        description={card.description}
                        icon={card.icon}
                        currency='$'
                    />
                ))}
            </div>
            {/* <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Provider List</span>
                </div>
                {isLoading ? <LoadingSpinner /> : <TableContent columns={columnsAccountList} data={accounts || []}></TableContent>}
            </div>
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Suppliers List</span>
                </div>
                {isLoading ? <LoadingSpinner /> : <TableContent columns={columnsSupplierList} data={suppliers || []}></TableContent>}
            </div> */}
            <div className="page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Recent Transactions</span>
                </div>
                <TableContent columns={columnsRecent} data={recentTransactions || []}></TableContent>
            </div>
            <div className="page-content limit-height">
                <StatisticalInfoCard
                    title="Total Portofolio Value"
                    value={totalAssets?.totalInUSD}
                    performanceToday="+ 200.000"
                    // performanceChange={`${totalAssets?.totalUsdValueEvolutionPercent} %`}
                    performanceChange={`${1.3} %`}
                />
            </div>

            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                    <span className="table-title">Assets</span>
                </div>
                {totalAssets &&
                    <TotalAssetsChart totalAssets={totalAssets} isPercent/>
                }
            </div>
            <div className="transaction-details-chart page-content flex-column flex-gap-20">
                <div className="table-header">
                    <span className="table-title">Transaction Details</span>
                </div>
                <TransactionsDetailsChart />

            </div>
            <div className='statistic-container flex-row flex-gap-20'>
                <div className='statistic-card flex-column flex-gap-20'>
                    <div className='card-title'>
                        <span>Top Providers</span>
                    </div>
                    <div className='doughnut-container flex-row flex-align-center-center'>
                        <DoughnutChart data={topProviders || []} theme='customers' />
                    </div>
                    <div className='doughnut-chart-legend flex-column flex-gap-15 flex-align-sp                 ace-between-center'>
                        {sortedProviders.map((provider, index) => (
                            <div key={provider.accountId} className='flex-row flex-gap-15 flex-align-center-center'>
                                <div className='flex-row flex-gap-10 flex-align-start-center'>
                                    <div className={`dot provider-${index + 1}`}></div>
                                    <span className='info'>{provider.accountName}</span>
                                </div>
                                <span className='value'>{formatNumber(provider.totalSpentInUsd)} $</span>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className='statistic-card flex-column flex-gap-20'>
                    <div className='card-title'>
                        <span>Featured Product</span>
                    </div>
                    <div className='content flex-row flex-align-center-'>
                        <div className='arrow-section width-50 flex-row flex-align-start-center'>
                            <div className='arrow-container flex-row flex-align-center-center'>
                                <ArrowIcon />
                            </div>
                        </div>
                        <div className='arrow-section width-50 flex-row flex-align-end-center'>
                            <div className='arrow-container flex-row flex-align-center-center'>
                                <ArrowIcon className='right-arrow' />
                            </div>
                        </div>

                    </div>
                    <div className='item-description flex-column flex-gap-10 width-100 flex-align-center-center'>
                        <span className='primary'>Best Item from sales 2024</span>
                        <span className='secondary'>$89.00</span>
                    </div>

                </div> */}
                <AssetAnalytics />
            </div>
        </div>
    )
}

export default DashboardPage;