import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);



interface DoughnutChartProps {
  theme: 'customers' | 'assets';
  data: any[];
  isPercent?: boolean
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ theme, data, isPercent = false }) => {
  const backgroundColors = theme === 'assets'
    ? ['#00B69B', '#FCBE2D', '#7F7F7F']
    : ['#6494FF', '#C0D2F0', '#7ACB79'];

  const chartLabels = data.map((item) => 
    theme === 'customers' ? item.accountName || '' : item.label || ''
  );

  const chartDataValues = data.map((item) =>
    theme === 'customers' ? item.totalSpentInUsd || 0 : item.value
  );

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: theme === 'assets' ? 'Asset Distribution' : '',
        data: chartDataValues,
        backgroundColor: backgroundColors,
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            return theme === 'customers'
              ? `${value.toLocaleString()} $`
              : `${value}%`;
          },
        },
      },
      datalabels: {
        display: theme === 'assets',
        color: '#fff',
        font: {
          size: 16,
          weight: 'bold' as const,
        },
        formatter: (value: any, context: any) => {
          const label = context.chart.data.labels?.[context.dataIndex];
          return value > 0 ? `${label}\n${value} ${isPercent ? '%': ''}` : '';
        },
      },
    },
    cutout: theme === 'assets' ? '50%' : '80%',
  };

  const doughnutLabelPlugin = {
    id: 'doughnutLabel',
    beforeDatasetsDraw: (chart: any) => {
      if (theme !== 'assets') return;

      const { width, height, ctx } = chart;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = chart._metasets[0].controller.innerRadius;

      ctx.fillStyle = '#ffffff';
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      ctx.fill();

      const fontSize = '16px';
      const fontWeight = '600';
      ctx.fillStyle = 'rgba(39, 49, 66, 0.7)';
      ctx.font = `${fontWeight} ${fontSize} sans-serif`;
      ctx.textBaseline = 'middle';

      const text1 = 'Asset';
      const text2 = 'Distribution';

      const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);

      const text1Y = centerY - 10;
      const text2Y = centerY + 10;

      ctx.fillText(text1, text1X, text1Y);
      ctx.fillText(text2, text2X, text2Y);

      ctx.save();
    },
  };

  return <Doughnut 
  data={chartData} 
  options={chartOptions} 
  plugins={theme === 'assets' ? [doughnutLabelPlugin] : []}
  />;
};

export default DoughnutChart;
