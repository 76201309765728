import StatisticalInfoCard from "../../../../components/statistical-info-card";
import { ReactComponent as BitcoinRoundedIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as PositiveIcon } from '../../../../assets/icons/positive.svg';
import { ReactComponent as NegativeIcon } from '../../../../assets/icons/negative.svg';
import { ReactComponent as BitcoinIcon } from '../../../../assets/icons/bitcoin-wallet.svg';
import { ReactComponent as USDTIcon } from '../../../../assets/icons/usdt-wallet.svg';
import { ReactComponent as EthereumIcon } from '../../../../assets/icons/ethereum-wallet.svg';
import { ReactComponent as BitcoinAssetsInsights } from '../../../../assets/icons/assets-insights.svg';
import './asset-management.scss';
import DoughnutChart from "../../../../components/doughnut-chart";
import CryptoCarousel from "../../../../components/custom-chart";
import { useEffect, useState } from "react";
import { apiService } from "../../../../services/api.service";
import { WalletCard } from "../../transaction-hub/transaction-hub-page";
import { TotalAssets } from "../../../../models/statistics.model";
import TotalAssetsChart from "../../../../components/total-assets-chart";





const AssetManagementPage = () => {
    const [totalAssets, setTotalAssets] = useState<TotalAssets>();
    useEffect(() => {
        const totalAssets = async () => {
            try {
                const result = await apiService.getTotalAssets();
                return result.data;
            } catch (e) {

            }

        }

        totalAssets().then((data) => {
            if (data)
                setTotalAssets(data)
        })
    }, [])

    return (
        <div className="asset-management-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Asset Management</span>
            </div>
            <div className="page-content limit-height">
                <StatisticalInfoCard
                    title="Total Portofolio Value"
                    value={totalAssets?.totalInUSD}
                    performanceToday="+ 200.000"
                    performanceChange={`1.3 %`}
                    // performanceChange={`${totalAssets?.totalUsdValueEvolutionPercent} %`}
                />
            </div>

            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                    <span className="table-title">Assets</span>
                </div>
                {totalAssets &&
                    <TotalAssetsChart totalAssets={totalAssets} isPercent/>
                }
            </div>
            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                </div>
            </div>
        </div>
    )
}

export default AssetManagementPage;