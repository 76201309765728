import { useState } from "react";
import { LabelValue } from "../types/label-value.types";
import AppSelect from "./form/base/app-select";
import './table-advanced-filter.scss';
import AppFormSelect from "./form/app-form-select";
import { useForm } from "react-hook-form";
import AppFormInput from "./form/app-form-input";
import Button from "./button";

const methodOptions = [
    {
        label: 'Received',
        value: 'RECEIVED'
    },
    {
        label: 'Sent',
        value: 'SENT'
    },
    {
        label: 'Internal Transfer',
        value: 'INTERNAL'
    }
]

const fromOptions = [
    {
        label: 'test1',
        value: 'test1'
    },
]

const toOptions = [
    {
        label: 'test2sfsdfsdfsdfdsssdsf',
        value: 'test2'
    },
]

interface TableAdvancedFilterForm {
    status: string;
    from: string;
    to: string;
    minDate: string;
    maxDate: string;
    minAmount: string;
    maxAmount: string;
}

interface TableAdvancedFilterProps {
    onApply: (filters: TableAdvancedFilterForm) => void;
}


const TableAdvancedFilter: React.FC<TableAdvancedFilterProps> = ({ onApply }) => {
    const form = useForm<TableAdvancedFilterForm>({
        mode: "onChange" 
      })
      
    const onSubmit = (data: TableAdvancedFilterForm) => {
        onApply(data);
    };

    return (
        <div className="advanced-filters-container flex-row flex-align-end-end width-100 flex-gap-20">
            <AppFormSelect
                name="status"
                control={form.control}
                options={methodOptions}
                placeholder="Method"
            />
            <div className="date-container flex-row flex-gap-20">
                <AppFormInput
                    name="minDate"
                    control={form.control}
                    label="Start Date:"
                    type="date"
                />
                <AppFormInput
                    name="maxDate"
                    control={form.control}
                    label="End Date:"
                    type="date"
                />
            </div>
            <AppFormSelect
                name="to"
                control={form.control}
                options={fromOptions}
                placeholder="From"
            />
            <AppFormSelect
                name="from"
                control={form.control}
                options={toOptions}
                placeholder="To"
            />

            <div className="amount-container flex-row flex-gap-20">
                <AppFormInput
                    name="minAmount"
                    control={form.control}
                    label="Minimum Amount:"
                    type="number"
                    validations={{ min: { value: 0, message: "Amount must be at least 0" } }}
                />
                <AppFormInput
                    name="maxAmount"
                    control={form.control}
                    label="Maximum Amount:"
                    type="number"
                    validations={{ min: { value: 0, message: "Amount must be at least 0" } }}
                />
            </div>
            <Button
            theme='form'
            label="Apply"
            onClick={form.handleSubmit(onSubmit)}
            />
        </div>
    )
}

export default TableAdvancedFilter;