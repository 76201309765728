import { useState } from "react";
import AppSelect from "./form/base/app-select";
import './table-filters.scss';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as AdvancedFiltersIcon } from '../assets/icons/advanced-filters.svg';

import TablePagination from "./table-pagination";
import Button from "./button";
import TableAdvancedFilter from "./table-advanced-filter";

const monthOptions = [
    {
        label: 'January',
        value: 'january'
    },
    {
        label: 'February',
        value: 'february'
    },
    {
        label: 'March',
        value: 'march'
    },
    {
        label: 'April',
        value: 'april'
    },
    {
        label: 'May',
        value: 'may'
    },
    {
        label: 'June',
        value: 'june'
    },
    {
        label: 'July',
        value: 'july'
    },
    {
        label: 'August',
        value: 'august'
    },
    {
        label: 'September',
        value: 'september'
    },
    {
        label: 'October',
        value: 'october'
    },
    {
        label: 'November',
        value: 'november'
    },
    {
        label: 'December',
        value: 'december'
    }
];
interface TableFiltersProps {
    onApplyFilters: (filters: any) => void;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}


const TableFilters: React.FC<TableFiltersProps> = ({ onApplyFilters, currentPage, totalPages, onPageChange  }) => {
    const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState('june');
    const changeMonth = (value: any) => {
        setSelectedMonth(value);
    }

    const handleAdvancedFiltersApply = (advancedFilters: any) => {
        onApplyFilters(advancedFilters);
    };
    return (
        <div className="table-filters-container flex-column flex-align-start-center flex-gap-20">
            <div className="filters-container flex-row flex-align-end-center width-100">
                <div className="month-selector filters-section">
                    {/* <AppSelect
                        options={monthOptions}
                        onChange={changeMonth}
                        value={selectedMonth}
                        icon={<CalendarIcon />}
                    /> */}
                </div>
                <div className="filters-section advanced-filter flex-row flex-align-end-center">
                    <div className={`advanced-filter-container ${isAdvancedFiltersOpen ? 'active' : ''}`}>
                        <Button
                            theme='filters-button'
                            onClick={() => { 
                                setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen) 
                                
                            }}
                            label='Advanced Filter'
                            icon={() => <AdvancedFiltersIcon />}
                        />
                    </div>
                </div>
                <div className="filters-section table-pagination flex-row flex-gap-10 flex-align-end-center">
                    <TablePagination 
                     currentPage={currentPage}
                     totalPages={totalPages}
                     onPageChange={onPageChange}
                    />
                </div>
            </div>
            {isAdvancedFiltersOpen && (
                <>
                    <div className="separator hide-md"></div>
                    <TableAdvancedFilter onApply={handleAdvancedFiltersApply}/>
                </>)}
        </div>

    )
}
export default TableFilters;