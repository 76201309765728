import { useEffect, useState } from "react";
import CustomChart from "./custom-chart";
import AppSelect from "./form/base/app-select";
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import './transactions-details-chart.scss';
import { apiService } from "../services/api.service";



const currentYear = new Date().getFullYear();

const yearOptions = Array.from({ length: 4 }, (_, index) => {
    const year = currentYear - index;
    return { label: year.toString(), value: year.toString() };
}).reverse();

type YearType = string;


const TransactionsDetailsChart = () => {
    const [selectedYear, setSelectedYear] = useState<YearType>(currentYear.toString());
    const [transactionData, setTransactionsData] = useState<number[]>();
    const changeYear = (value: any) => {
        setSelectedYear(value as YearType);
    };

    useEffect(() => {

        const getTransactionsKPI = async () => {
            try {
                const result = await apiService.getAnuallyTransactions(selectedYear)
                return result.data
            } catch (e) {
                console.log(e);

            }
        }

        getTransactionsKPI().then((data) => {
            if (data) {
                const formattedData = [
                    data.januaryTransactions,
                    data.februaryTransactions,
                    data.marchTransactions,
                    data.aprilTransactions,
                    data.mayTransactions,
                    data.juneTransactions,
                    data.julyTransactions,
                    data.augustTransactions,
                    data.septemberTransactions,
                    data.octoberTransactions,
                    data.novemberTransactions,
                    data.decemberTransactions,
                ];
                setTransactionsData(formattedData);
            }
        })

    }, [selectedYear]);

    return (
        <>
            <CustomChart data={transactionData || []} />
        </>
    );
};

export default TransactionsDetailsChart;