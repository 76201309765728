import React, { ReactNode } from 'react';
import './wallet-card.scss';
import { ReactComponent as PositiveIcon } from '../assets/icons/positive.svg';
import { ReactComponent as NegativeIcon } from '../assets/icons/negative.svg';
import { WalletCard as WalletCardType } from '../router/private/transaction-hub/transaction-hub-page';
import { formatNumber } from '../utils/number-utils';



interface WalletCardProps extends WalletCardType {
  selected?: boolean;
  onSelect?: (wallet: WalletCardType) => void;
  currency: string;
};




const WalletCard: React.FC<WalletCardProps> = ({ walletType, total, percentageChange, description, icon, selected, onSelect, currency }) => {
  const PercentageIcon = percentageChange >= 0 ? PositiveIcon : NegativeIcon; 

  const handleSelect = (walletType:string) => {
    if(onSelect)
      onSelect({ walletType, total, percentageChange, description, icon });
  }
  return (
    <div className={`wallet-card ${selected ? 'selected' : ''} ${onSelect ? 'hoverable' : ''}`} onClick={() => handleSelect(walletType)}>
      <div className="wallet-card-content flex-align-space-between-normal flex-gap-25">
        <div className="wallet-card-header">
          <div className='flex-column flex-align-space-around-start'>
          <span>{walletType}{' Wallets'}</span>
          <div className="wallet-card-total">{formatNumber(total)} {currency}</div>
          </div>
            {icon ? icon : null}
        </div>
        <div className="flex-row flex-gap-5">
          <PercentageIcon/>
          <span className={`percentage-change ${percentageChange >= 0 ? 'positive' : 'negative'}`}>
            {percentageChange}{'%'}
          </span>
          <span>{description}</span>
        </div>
      </div>
    </div>
  );
};

export default WalletCard;
